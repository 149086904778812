import { Container } from "@mui/material";
import React from "react";

const FAQ = () => {
  const faqData = [
    {
      question: "How do I create an account?",
      answer:
        "To create an account, click on the 'Sign Up' button on the top right corner of the page. Provide your information and follow the steps to set up your account.",
    },
    {
      question: "How can I search for properties?",
      answer:
        "You can search for properties using the search bar on the homepage. Enter keywords like city, neighborhood, or address to find properties that match your criteria.",
    },
    {
      question: "Can I filter properties by price range?",
      answer:
        "Yes, you can filter properties by price range. Use the price filter options on the search results page to narrow down your choices.",
    },
    // Add more FAQ items as needed
  ];

  return (
    <Container>
      <div className="faq mt-4">
        <h1>Frequently Asked Questions</h1>
        <div className="faq-list">
          {faqData.map((faq, index) => (
            <div key={index} className="faq-item">
              <h2>{faq.question}</h2>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default FAQ;
