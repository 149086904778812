import React from "react";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

const Profile = () => {
  // get the user from redux store
  const user = useSelector((state: RootState) => state.user.userData);

  return (
    <div className="container mt-4">
      <h1>My Profile</h1>
      <p>{user?.name}</p>
      <hr />
      <p>{user?.email}</p>
      <hr />
      <p>{user?.address}</p>
      <hr />
      <p>{user?.phone}</p>
    </div>
  );
};

export default Profile;
