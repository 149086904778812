import { Container } from "@mui/material";
import React from "react";
import "./reset.scss";

import { useFormik } from "formik";
import * as Yup from "yup";
import { resetPasswordService } from "../../service/userService/userService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// validation schema for login form
const userSchema = Yup.object({
  password: Yup.string()
    .min(8, "Minimum 8 characters required")
    .required("Password is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/,
      "Password must contain at least one letter, one number, and one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetPassword = () => {
  // manage login and signup forms state
  const token = window.location.pathname.split("/")[2];

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      // handle the form submission here
      const password = values.confirmPassword;

      resetPasswordService(password, toast, token);
    },
  });

  return (
    <Container maxWidth="md">
      <ToastContainer />
      <h1>Change Password</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="password">
            New Password <span>*</span>{" "}
          </label>
          <input
            type="password"
            className="form-control"
            required
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password && formik.errors.password ? (
            <span className="text-danger">{formik.errors.password}</span>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">
            Confirm Password <span>*</span>{" "}
          </label>
          <input
            type="password"
            className="form-control"
            required
            id="confirmPassword"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <span className="text-danger">{formik.errors.confirmPassword}</span>
          ) : null}
        </div>
        <button type="submit" className="btn">
          Change Password
        </button>
      </form>
    </Container>
  );
};

export default ResetPassword;
