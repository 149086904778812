import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import properSlice from "./slices/propertySlice";

export const store = configureStore({
  reducer: {
    //userReducer
    user: userSlice,

    // propertyReducer
    properties: properSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
