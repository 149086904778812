import { Container } from "@mui/material";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Container>
      <div className="privacy-policy">
        <h1>Privacy Policy for HjemProperties</h1>
        <p>Effective Date: 30.08.2023</p>
        <p>
          Thank you for using HjemProperties. This Privacy Policy explains how
          we collect, use, disclose, and safeguard your information when you use
          our mobile application or website ("Application"). By using the
          Application, you consent to our Privacy Policy and agree to the terms
          outlined below.
        </p>

        <h2>Information We Collect</h2>

        <h3>Personal Information</h3>
        <p>
          We may collect certain personal information from you, such as your
          name, email address, and contact information. This information is
          collected when you provide it voluntarily through the Application,
          such as when you register an account, subscribe to newsletters, or
          contact us.
        </p>

        <h3>Usage Data</h3>
        <p>
          We may automatically collect certain information when you use the
          Application, such as your IP address, device type, operating system,
          and browsing activity. This information helps us understand how you
          interact with the Application and improve its functionality.
        </p>

        <h2>How We Use Your Information</h2>
        <p>
          We use the information we collect for various purposes, including:
        </p>
        <ul>
          <li>Providing, maintaining, and improving the Application.</li>
          <li>
            Personalizing your experience and delivering relevant content.
          </li>
          <li>Responding to your inquiries, comments, or feedback.</li>
          <li>
            Sending you administrative messages, updates, and promotional
            materials.
          </li>
          <li>Monitoring and analyzing usage patterns and trends.</li>
        </ul>

        <h2>Disclosure of Your Information</h2>
        <p>
          We do not sell, rent, or share your personal information with third
          parties for marketing purposes. However, we may share your information
          under certain circumstances, such as:
        </p>
        <ul>
          <li>
            With trusted service providers to perform functions on our behalf.
          </li>
          <li>
            In response to legal requests or obligations to protect our rights,
            privacy, safety, or property.
          </li>
        </ul>

        <h2>Data Security</h2>
        <p>
          We take data security seriously and use industry-standard measures to
          protect your information from unauthorized access, alteration, or
          disclosure. However, please note that no method of transmission over
          the internet or electronic storage is completely secure.
        </p>

        {/* Add more sections here based on the template in the previous response */}
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
