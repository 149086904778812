import React, { ChangeEvent, useEffect, useState } from "react";
import "./HomePageBanner.scss";
import { TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAllPropertiesService } from "../../../service/propertyService";
import { useNavigate } from "react-router-dom";

// validation schema
const validationSchema = Yup.object({
  searchQuery: Yup.string(),
});

const HomePageBanner = () => {
  // state for search query
  const [filteredProperties, setFilteredProperties] = useState<any[]>([]);
  const [properties, setProperties] = useState<any[]>([]);

  // navigate to search results page
  const navigate = useNavigate();

  // get properties
  useEffect(() => {
    const getProperties = async () => {
      const res = await getAllPropertiesService();
      setProperties(res.properties);
    };
    getProperties();
  }, []);

  // formik validation for search query
  const formik = useFormik({
    initialValues: {
      searchQuery: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      // Filter properties here based on the formik.values.searchQuery
      const filtered = properties.filter((property) => {
        return (
          property.city
            .toLowerCase()
            .includes(formik.values.searchQuery.toLowerCase()) ||
          property.location
            .toLowerCase()
            .includes(formik.values.searchQuery.toLowerCase())
        );
      });
      setFilteredProperties(filtered);

      // navigate to search results page
      navigate("/search-results", {
        state: { properties: filteredProperties },
      });
    },
  });

  // handle search click
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  // get properties
  useEffect(() => {
    const getProperties = async () => {
      const res = await getAllPropertiesService();
      setProperties(res.properties);
    };
    getProperties();

    // filter properties
    if (formik.values.searchQuery !== "") {
      const filtered = properties.filter((property) => {
        return (
          property.city
            .toLowerCase()
            .includes(formik.values.searchQuery.toLowerCase()) ||
          property.location
            .toLowerCase()
            .includes(formik.values.searchQuery.toLowerCase())
        );
      });
      setFilteredProperties(filtered);
    }
  }, [formik.values.searchQuery]);

  return (
    <div className="banner__container">
      <Grid className="banner__grid">
        <Grid item xs={12} md={6}>
          <Typography variant="h4" className="banner__title">
            10.055 Properties for Rent
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              className="form-control banner__search"
              id="exampleFormControlInput1"
              placeholder="Search by city, neighborhood, or address"
              name="searchQuery"
              onChange={formik.handleChange}
            />
            <button type="submit" style={{ display: "none" }}></button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePageBanner;
