import { createSlice } from "@reduxjs/toolkit";

// Load data from local storage
const storedData = localStorage.getItem("userData");
const initialUserData = storedData ? JSON.parse(storedData) : null;

// Interface for user state
interface IUserState {
  userData: any;
  token: string | null;
  isLoggedIn: boolean;
}

// Initial state
const initialState: IUserState = {
  userData: initialUserData?.userData || null,
  token: initialUserData?.token || null,
  isLoggedIn: initialUserData?.isLoggedIn || false,
};

// User slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      const { user, token } = action.payload;
      state.userData = user;
      state.token = token;
      state.isLoggedIn = true;
      // Update local storage when user logs in
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userData: state.userData,
          token: state.token,
          isLoggedIn: state.isLoggedIn,
        })
      );
    },
    logout: (state) => {
      state.userData = {};
      state.token = null;
      state.isLoggedIn = false;
      localStorage.removeItem("userData");
    },
  },
});

// Export actions
export const { login, logout } = userSlice.actions;

// Export reducer
export default userSlice.reducer;
