import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./carousel.scss";
import { ArrowForward } from "@mui/icons-material";
import { Container } from "@mui/material";
import { formatDistanceToNow } from "date-fns";

const Carousel = ({ images, property }: any) => {
  // date created
  const dateCreated = new Date(property.createdAt);

  return (
    <>
      <div id="carouselExampleFade" className="carousel slide carousel-fade">
        <div className="carousel-inner">
          {images.map((image: any, index: number) => (
            <div
              className={`carousel-item ${index === 0 ? "active" : ""}`}
              key={index}
            >
              <img
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
                src={image}
                className="d-block w-100"
                alt={`Image ${index + 1}`}
              />
            </div>
          ))}
        </div>
        <button
          className="prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <ArrowBackIcon className="carousel-icon" />
        </button>
        <button
          className="next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <ArrowForward className="carousel-icon" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <Container>
        <span>{formatDistanceToNow(dateCreated)} ago</span>
      </Container>
    </>
  );
};

export default Carousel;
