import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loginUserService } from "../../service/userService/userService";
import { set } from "date-fns";
import { useDispatch } from "react-redux";
import { login } from "../../redux/slices/userSlice";

// validation schema for login form
const userSchema = Yup.object({
  email: Yup.string().required("Email is required").email("Invalid email"),
  password: Yup.string()
    .min(8, "Minimum 8 characters required")
    .required("Password is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/,
      "Password must contain at least one letter, one number, and one special character"
    ),
});

const Login = ({ handleClose }: { handleClose: () => void }) => {
  const [userMessage, setUserMessage] = React.useState("");

  // dispatch action to store the user in the redux store
  const dispatch = useDispatch();

  // manage login and signup forms state
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: userSchema,
    onSubmit: async (values) => {
      try {
        // handle the form submission
        const res = await loginUserService(values);
        const { token, user } = res;

        if (res.message === "Login successful") {
          // dispatch the action to store the user in the redux store
          dispatch(login({ token, user, isLoggedIn: true }));
          handleClose();
        } else {
          setUserMessage(res);

          // Clear the message after 5 seconds
          setTimeout(() => {
            setUserMessage("");
          }, 5000);
        }

        // Remove query parameters from the URL
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete("email");
        currentUrl.searchParams.delete("password");
        window.history.replaceState({}, document.title, currentUrl.href);
      } catch (error) {
        setUserMessage("An error occurred during login");
        // Clear the error message after 5 seconds
        setTimeout(() => {
          setUserMessage("");
        }, 5000);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3 mt-2">
          <label htmlFor="email" className="form-label">
            Email <span className="required">*</span>
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            required
            {...formik.getFieldProps("email")}
          />
        </div>

        <div className="mb-3 mt-2">
          <label htmlFor="password" className="form-label">
            Password <span className="required">*</span>
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            required
            {...formik.getFieldProps("password")}
          />
        </div>
        {userMessage && (
          <div className="mb-3 mt-2">
            <p className="text-danger">{userMessage}</p>
          </div>
        )}
        <div>
          <button type="submit" className="btn btn-primary">
            Sign In
          </button>
        </div>
      </form>
    </>
  );
};

export default Login;
