import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactLanlord = ({ property }: any) => {
  const [propertyItem, setPropertyItem] = useState<any>(null);
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    setPropertyItem(property);
  }, [property]);

  // check if the user is logged in
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

  // handle click
  const handleClick = () => {
    if (!isLoggedIn) {
      toast.error("You need to be logged in to contact landlord", {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
      });
      return;
    }
    setClicked(true);
  };

  return (
    <>
      <ToastContainer />
      <div className="mt-3 d-flex justify-content-between ">
        <div className="mt-4">
          <span
            style={{
              fontSize: "0.7rem",
            }}
          >
            Monthly net rent
          </span>
          <div>
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              GHS {propertyItem?.price ? propertyItem?.price : ""}
            </span>
          </div>
        </div>
        <div>
          <button onClick={handleClick} className="btn">
            {clicked ? (
              <a
                href={`tel:+233${
                  propertyItem?.phone ? propertyItem?.phone : ""
                }`}
                style={{
                  color: "#fff",
                  textDecoration: "none",
                }}
              >
                {" "}
                (+233){propertyItem?.phone}
              </a>
            ) : (
              "Contact landlord"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default ContactLanlord;
