import React from "react";
import "./styles.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const RentYourPlace = () => {
  return (
    <>
      <div className="rent-yourplace">
        <h3>Rent out your place and find your next tenant - for free</h3>
        <span>
          Tired of paying money to rent out your apartment or house ? Use
          HjemProperties and find your new tenant for free
        </span>
        <div>
          <CheckCircleIcon className="check__icon" />
          <span>Only get contact by serious seekers</span>
        </div>
        <div>
          <CheckCircleIcon className="check__icon" />
          <span>Choose new tenants by yourself</span>
        </div>
        <a href="/add-property">
          <button className="btn">Rent out your property - for free</button>
        </a>
      </div>
    </>
  );
};

export default RentYourPlace;
