import React from "react";
import { Container } from "@mui/material";
import "./styles.scss";
import DisplayProperties from "../DisplayProperties/DisplayProperties";

const AllProperties = () => {
  return (
    <Container>
      <div className="d-flex gap-2 ms-1 mt-1 property__container">
        <div>
          <a href="/">
            <span>Home </span>
          </a>
        </div>
        <div>{">"}</div>
        <div>
          <a href="/properties">
            <span> All properties</span>
          </a>
        </div>
      </div>
      <DisplayProperties />
    </Container>
  );
};

export default AllProperties;
