import { initializeApp } from "firebase/app";
import "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCNn25DiSxKWNPGOdbib3eIDXWdeTW1ItY",
  authDomain: "hjemproperties-5f607.firebaseapp.com",
  projectId: "hjemproperties-5f607",
  storageBucket: "hjemproperties-5f607.appspot.com",
  messagingSenderId: "523807371693",
  appId: "1:523807371693:web:60f4d8fbbd23e79aba7124",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
