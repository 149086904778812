import React from "react";
import IndexRouter from "./routes";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

const baseUrlAPI = process.env.REACT_APP_API;

const App = () => {
  const client = new ApolloClient({
    uri: `${baseUrlAPI}/graphql/property`,

    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <IndexRouter />
    </ApolloProvider>
  );
};

export default App;
