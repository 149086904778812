import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import UserModal from "../../modals/UserModal";
import "./navbar.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/slices/userSlice";
import { RootState } from "../../../redux/store";

const AppBarMain = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // navigation
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // handle logout
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");

    // refresh the page
    window.location.reload();
  };

  // get the user from redux
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

  // get the user from redux
  const user = useSelector((state: RootState) => state.user.userData);

  return (
    <AppBar position="static">
      <Container className="main__container " maxWidth="xl">
        <Toolbar disableGutters>
          <NavLink to="/">
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".2rem",
                color: "#ED6B2D",
                textDecoration: "none",
              }}
            >
              HjemProperties
            </Typography>
          </NavLink>

          {/* Navigation Menu for smaller screen */}
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "flex",
                md: "none",
              },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            {/* Smaller screen menu */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <NavLink to="/properties">
                  <Typography className="text" textAlign="center">
                    Properties
                  </Typography>
                </NavLink>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <NavLink to="/add-property">
                  <Typography className="text" textAlign="center">
                    Create Property
                  </Typography>
                </NavLink>
              </MenuItem>
            </Menu>
            {/* End Smaller screen menu */}
          </Box>
          {/* End Navigation Menu for smaller screen */}

          {/* Logo */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".2rem",
              color: "#ED6B2D",
              textDecoration: "none",
            }}
          >
            HjemProperties
          </Typography>
          {/* End Logo */}

          {/* Bigger screen display */}
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
            }}
          >
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
              className="text"
            >
              <NavLink to="/properties">
                <Typography className="text" textAlign="center">
                  Properties
                </Typography>
              </NavLink>
            </Button>

            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
              className="text"
            >
              <NavLink to="/add-property">
                <Typography className="text" textAlign="center">
                  Create Property
                </Typography>
              </NavLink>
            </Button>
          </Box>
          {/* End Bigger screen display */}

          {/* User avatar */}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={(user && user.name) || "H"}
                  src="/static/images/avatar/2.jpg"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {isLoggedIn && (
                <MenuItem onClick={handleCloseUserMenu}>
                  <NavLink to="/profile">
                    <Typography textAlign="center">Profile</Typography>
                  </NavLink>
                </MenuItem>
              )}
              <MenuItem onClick={handleCloseUserMenu}>
                <NavLink to="/">
                  <Typography textAlign="center">Dashboard</Typography>
                </NavLink>
              </MenuItem>

              {
                // if the user is not logged in, show the login button
                !isLoggedIn && (
                  <MenuItem onClick={handleCloseUserMenu}>
                    <UserModal />
                  </MenuItem>
                )
              }

              {
                // if the user is logged in, show the logout button
                isLoggedIn && (
                  <MenuItem onClick={handleLogout}>
                    <Typography onClick={handleLogout} textAlign="center">
                      Logout
                    </Typography>
                  </MenuItem>
                )
              }
            </Menu>
          </Box>
          {/* End User avatar */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AppBarMain;
