import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import HomeIcon from "@mui/icons-material/Home";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import "./categories.scss";
import { NavLink } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const Categories = () => {
  const apartments = "apartments";
  const rooms = "rooms";
  const houses = "houses";
  const townHouses = "town houses";

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <h2 className="mt-4">Categories</h2>
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={6} md={6}>
            <NavLink to={`/category/${apartments}`}>
              <Item className="categories__item">
                <ApartmentIcon />
                <h5>Apartment</h5>
              </Item>
            </NavLink>
          </Grid>
          <Grid item xs={6} md={6}>
            <NavLink to={`/category/${rooms}`}>
              <Item className="categories__item">
                <LocationCityIcon />
                <h5>Rooms</h5>
              </Item>
            </NavLink>
          </Grid>
          <Grid item xs={6} md={6}>
            <NavLink to={`/category/${houses}`}>
              <Item className="categories__item">
                <HomeIcon />
                <h5>House</h5>
              </Item>
            </NavLink>
          </Grid>
          <Grid item xs={6} md={6}>
            <NavLink to={`/category/${townHouses}`}>
              <Item className="categories__item">
                <OtherHousesIcon />
                <h5>Town Houses</h5>
              </Item>
            </NavLink>
          </Grid>
          <Grid item xs={12} md={12}>
            <NavLink to="/properties">
              <Item className="categories__item">
                <ApartmentIcon />
                <h5>All properties</h5>
              </Item>
            </NavLink>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Categories;
