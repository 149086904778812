import axios from "axios";

const baseUrlAPI = process.env.REACT_APP_API;

// get all categories service
export const getCategoriesService = async () => {
  try {
    const res = await axios.get(`${baseUrlAPI}/categories`);
    return res.data;
  } catch (error: any) {
    const err = error.response.data;
    return err.message;
  }
};
