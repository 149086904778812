import { Box, Container, Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { formatDistanceToNow } from "date-fns";
import "./search.scss";

const SearchResults = () => {
  const location = useLocation();
  const filteredProperties = location.state.properties;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Container className=" d-flex gap-2">
        <div style={{ fontSize: "0.8rem" }}>Home</div>
        <div style={{ fontSize: "0.8rem" }} className="headernav">
          {">"}
        </div>
        <div style={{ fontSize: "0.8rem" }} className="header">
          All properties
        </div>
        <div style={{ fontSize: "0.8rem" }} className="header">
          {">"}
        </div>
        <div style={{ fontSize: "0.8rem" }} className="header">
          {filteredProperties.length > 0
            ? filteredProperties[0].city
            : "No results found"}
        </div>
      </Container>

      <Container className="mt-5 mb-5">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {filteredProperties.length > 0 ? (
              filteredProperties.map((property: any) => (
                <Grid key={property._id} item xs={12} md={4} lg={3}>
                  <Link to={`/properties/${property.slug}`}>
                    <Item className="property__content">
                      <div className="property__image">
                        <img
                          src={property.image[0]}
                          alt={property.title}
                          style={{ height: "250px", objectFit: "cover" }} // Set the fixed height and object-fit
                        />
                      </div>
                      <div className="property__info">
                        <h5>
                          {property.rooms} rm. <span className="dot">·</span>{" "}
                          {property.category?.name}
                          <span className="dot">·</span> {property.size} m²
                        </h5>
                        <br />
                        <span>{property.location}</span>
                        <br />
                        <span>{property.title}</span>
                        <br />
                        <div className="d-flex mt-2 gap-4">
                          <span className="date">
                            {formatDistanceToNow(new Date(property.createdAt), {
                              addSuffix: true,
                            })}
                          </span>
                          <h5>{property.price} GHS</h5>
                        </div>
                      </div>
                    </Item>
                  </Link>
                </Grid>
              ))
            ) : (
              <div className="no__results">
                <h2>No results found</h2>
                <p>
                  We couldn't find anything for your search. Try a different
                  location or just browse around.
                </p>
                <Link to="/">Back to homepage</Link>
              </div>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default SearchResults;
