import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAllPropertiesService } from "../../service/propertyService";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  height: "100%", // Set a fixed height for the card content
}));

const DisplayCategory = () => {
  const [properties, setProperties] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(8);

  // get the params from the url
  const { name } = useParams();

  // get the properties from the database
  useEffect(() => {
    const getProperties = async () => {
      const response = await getAllPropertiesService();
      setProperties(response.properties);
    };
    getProperties();
  }, []);

  // filter the properties based on the category name
  const filteredProperties = properties.filter(
    (property: any) => property.category?.name === name
  );

  // Calculate current properties to display based on pagination
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties =
    filteredProperties &&
    filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);

  return (
    <>
      <div className="mt-5 mb-5">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {currentProperties ? (
              currentProperties.map((property: any) => (
                <Grid key={property._id} item xs={12} md={4} lg={3}>
                  <Link to={`/properties/${property.slug}`}>
                    <Item className="property__content">
                      <div className="property__image">
                        <img src={property.image[0]} alt={property.title} />
                      </div>
                      <div className="property__info">
                        <h5>
                          {property.rooms} rm. <span className="dot">·</span>{" "}
                          {property.category?.name}
                          <span className="dot">·</span> {property.size} m²
                        </h5>
                        <br />
                        <span>{property.location}</span>
                        <br />
                        <span>{property.title}</span>
                        <br />
                        <div className="d-flex mt-2 gap-4">
                          <span className="date">
                            {formatDistanceToNow(new Date(property.createdAt), {
                              addSuffix: true,
                            })}
                          </span>
                          <h5>{property.price} GHS</h5>
                        </div>
                      </div>
                    </Item>
                  </Link>
                </Grid>
              ))
            ) : (
              <h1>Loading</h1>
            )}
          </Grid>
        </Box>
      </div>

      {/* Pagination controls */}
      <div className="pagination mb-5">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>{currentPage}</span>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={indexOfLastProperty >= properties.length}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default DisplayCategory;
