import React, { useEffect, useState } from "react";
import { Box, Typography, Container, CircularProgress } from "@mui/material";
import Lottie from "react-lottie";
import animationData from "../../components/lottie-animations/loading.json";
import { useNavigate, useParams } from "react-router-dom";
import { activateUserService } from "../../service/userService/userService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Activate = () => {
  const [activationStatus, setActivationStatus] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  // navigation to login page
  const navigate = useNavigate();

  // Get token from URL params
  const getTokenFromUrl: any = useParams<{ token: string }>();

  // Activate user account
  const activateUserAccount = async () => {
    const response = await activateUserService(getTokenFromUrl.id);
    console.log(response);

    if (response !== "User already exist") {
      setActivationStatus(true);
      setIsVerified(true);
      toast.success("Account activated successfully");
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      setActivationStatus(true);
      setIsVerified(false);
      toast.error(response, {
        type: "error",
      });
    }
  };

  // Activate user account on component mount
  useEffect(() => {
    activateUserAccount();
  }, []);

  // Lottie animation options
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", mt: 10 }}>
      <ToastContainer />
      <Box>
        {activationStatus && isVerified === true ? (
          <>
            <Lottie options={animationOptions} height={200} width={200} />
            <Typography variant="h5" sx={{ mt: 2 }}>
              Account activated successfully
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              You can now login to your account
            </Typography>
          </>
        ) : (
          <>
            <CircularProgress />
            <Typography variant="h5" sx={{ mt: 2 }}>
              User already verified, please login
            </Typography>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Activate;
