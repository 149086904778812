import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import "./styles.scss";
import AdjustIcon from "@mui/icons-material/Adjust";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getCategoriesService } from "../../service/categoryService";
import app from "../../service/firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { toast, ToastContainer } from "react-toastify";
import { createPropertyService } from "../../service/propertyService";

// Property Validation Schema
const propertySchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  price: Yup.number().required("Price is required"),
  size: Yup.number().required("size is required"),
  rooms: Yup.number().required("Room is required"),
  deposit: Yup.number().required("Deposit is required"),
  location: Yup.string().required("Location is required"),
  city: Yup.string().required("City is required"),
  phone: Yup.number().required("Phone is required"),
  country: Yup.string().required("Country is required"),
  bathrooms: Yup.number().required("Bathroom is required"),
  availablefrom: Yup.date().required("Move-in-date is required"),
  furnished: Yup.boolean().required("Furnished is required"),
  balcony: Yup.boolean().required("Balcony is required"),
  parking: Yup.boolean().required("Parking is required"),
  image: Yup.array().required("Images are required"),
  category: Yup.array().required("Category is required"),
});

const CreateProperty = () => {
  // Error state
  const [error, setError] = useState<string | null>(null);

  // get landlord info
  const landlordinfo = useSelector((state: RootState) => state.user.userData);

  // token  from redux store
  const token = useSelector((state: any) => state.user.token);

  // manage property form initial state
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      price: 0,
      size: 0,
      deposit: 0,
      location: "",
      landlord: "",
      city: "",
      phone: 0,
      country: "",
      bathrooms: 0,
      availablefrom: "",
      furnished: false,
      balcony: false,
      parking: false,
      images: [], // for storing images before uploading to firebase storage
      category: "",
      categories: [],
      rooms: 0,
    },
    validationSchema: propertySchema,

    onSubmit: async (values) => {},
  });

  // fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await getCategoriesService();
        formik.setFieldValue("categories", categoriesData.categories);
      } catch (error) {
        return error;
      }
    };
    fetchCategories();
  }, []);

  console.log(formik.values);

  const handleSubmit = async (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    // upload images to firebase storage
    const storage = getStorage(app);

    const uploadedImageUrls: string[] = [];

    // Loop through images and upload them to firebase storage
    for (const image of formik.values.images as any) {
      const uniqueFileName = `${Date.now()}-${image.name}`;
      const storageRef = ref(storage, uniqueFileName);
      const uploadTask = uploadBytesResumable(storageRef, image);

      await uploadTask;

      // Get the download URL
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

      // Add the download URL to the array
      uploadedImageUrls.push(downloadURL);
    }

    // check if the images  are uploaded successfully
    if (uploadedImageUrls.length === formik.values.images.length) {
      // property data to be sent to the server
      const propertyData = {
        ...formik.values,
        image: uploadedImageUrls,
      };

      // create property
      try {
        createPropertyService(propertyData, token, toast);
      } catch (error) {
        return error;
      }
    }
  };

  return (
    <div className="create__container">
      <ToastContainer />
      <div className="card create-card">
        <div className="card-body">
          <h3 className="card-title">Create rentable</h3>
          <hr className="create__hr" />
          <form className="mt-4" onSubmit={formik.handleSubmit}>
            {/* Upload Images */}
            <div className="mb-3">
              <h5>Upload images...</h5>
              <div className="image__container">
                <div className="upload-filetext">
                  <label htmlFor="image-upload" className="file-text">
                    Drag & Drop images here, or click to <br></br>select images
                    to upload.
                  </label>
                </div>
                <div className="upload-file mt-4">
                  <label htmlFor="image-upload" className="file-label">
                    Browse Files...
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="image-upload"
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                    style={{ display: "none" }}
                    multiple
                    onChange={(event) => {
                      const newImages = Array.from(
                        event.target.files as FileList
                      );

                      // Check if the total number of images is less than or equal to 4
                      if (formik.values.images.length + newImages.length <= 8) {
                        formik.setFieldValue("images", [
                          ...formik.values.images,
                          ...newImages,
                        ]);
                      } else {
                        setError("You can upload a maximum of 4 images");
                        // If more than 4 images, display error for 3 seconds
                        setTimeout(() => {
                          setError(null);
                        }, 3000);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            {/* End of Upload Images */}

            {/* preview images */}
            <div className="image-preview">
              {/* Use d-flex class to make images appear in a row */}
              <div className="d-flex gap-2 flex-wrap mt-2">
                {formik.values.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={URL.createObjectURL(image) || ""}
                      alt={`Preview ${index + 1}`}
                      className="image-preview__item"
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                    />
                  </div>
                ))}
                {
                  // If there is an error, display it
                  error && <div className="error">{error}</div>
                }
              </div>
            </div>

            {/* end  preview images */}

            <div className="room__size mt-3">
              <div className="room ">
                <span>
                  Rooms<span className="required">*</span>
                </span>
                <input
                  type="number"
                  min={1}
                  className="form-control"
                  {...formik.getFieldProps("rooms")}
                />
              </div>

              <div className="room">
                <span>
                  Size<span className="required">*</span>
                </span>
                <input
                  type="number"
                  min={1}
                  className="form-control"
                  {...formik.getFieldProps("size")}
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                {...formik.getFieldProps("title")}
              />
              {
                // If there is an error, display it
                formik.touched.title && formik.errors.title ? (
                  <div className="error">{formik.errors.title}</div>
                ) : null
              }
            </div>

            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description<span className="required">*</span>
              </label>
              <textarea
                className="form-control"
                id="description"
                rows={3}
                {...formik.getFieldProps("description")}
              ></textarea>
              {
                // If there is an error, display it
                formik.touched.description && formik.errors.description ? (
                  <div className="error">{formik.errors.description}</div>
                ) : null
              }
            </div>

            <div className="mb-3">
              <label htmlFor="price" className="form-label">
                Monthly Rent<span className="required">*</span>
              </label>
              <input
                type="number"
                min={0}
                className="form-control"
                id="price"
                {...formik.getFieldProps("price")}
              />
              {
                // If there is an error, display it
                formik.touched.price && formik.errors.price ? (
                  <div className="error">{formik.errors.price}</div>
                ) : null
              }
            </div>

            <div className="mb-3">
              <label htmlFor="deposit" className="form-label">
                Deposit<span className="required">*</span>
              </label>
              <input
                type="number"
                min={0}
                className="form-control"
                id="deposit"
                {...formik.getFieldProps("deposit")}
              />
              {
                // If there is an error, display it
                formik.touched.deposit && formik.errors.deposit ? (
                  <div className="error">{formik.errors.deposit}</div>
                ) : null
              }
            </div>

            <div className="mb-3">
              <label htmlFor="location" className="form-label">
                Location<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="location"
                {...formik.getFieldProps("location")}
              />
              {
                // If there is an error, display it
                formik.touched.location && formik.errors.location ? (
                  <div className="error">{formik.errors.location}</div>
                ) : null
              }
            </div>

            <div className="mb-3">
              <label htmlFor="city" className="form-label">
                City<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                {...formik.getFieldProps("city")}
              />
              {
                // If there is an error, display it
                formik.touched.city && formik.errors.city ? (
                  <div className="error">{formik.errors.city}</div>
                ) : null
              }
            </div>

            <div className="mb-3">
              <label className="form-label">
                Category
                <span className="required">*</span>
              </label>
              <select
                className="form-select"
                {...formik.getFieldProps("category")}
              >
                <option value="" disabled>
                  Choose a category
                </option>
                {
                  // display categories
                  formik.values.categories.map(
                    (category: any, index: number) => (
                      <option key={index} value={category._id}>
                        {category.name}
                      </option>
                    )
                  )
                }
              </select>
              {
                // display error message
                formik.errors.price && formik.touched.category ? (
                  <div className="error">{formik.errors.category}</div>
                ) : null
              }
            </div>

            <div className="mb-3">
              <label className="form-label">Landlord</label>
              <input
                type="text"
                className="form-control"
                {...formik.getFieldProps("landlord")}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Phone<span className="required">*</span>
              </label>
              <input
                type="number"
                min={0}
                className="form-control"
                id="phone"
                placeholder="233 244 123 456"
                {...formik.getFieldProps("phone")}
              />
              {
                // If there is an error, display it
                formik.touched.phone && formik.errors.phone ? (
                  <div className="error">{formik.errors.phone}</div>
                ) : null
              }
            </div>

            <div className="mb-3">
              <label htmlFor="country" className="form-label">
                Country<span className="required">*</span>
              </label>
              <input
                type="text"
                min={0}
                className="form-control"
                id="country"
                {...formik.getFieldProps("country")}
              />
              {
                // If there is an error, display it
                formik.touched.country && formik.errors.country ? (
                  <div className="error">{formik.errors.country}</div>
                ) : null
              }
            </div>

            <div className="mb-3">
              <label htmlFor="bathroom" className="form-label">
                Bathroom<span className="required">*</span>
              </label>
              <input
                type="number"
                min={0}
                className="form-control"
                id="bathroom"
                {...formik.getFieldProps("bathrooms")}
              />
              {
                // If there is an error, display it
                formik.touched.bathrooms && formik.errors.bathrooms ? (
                  <div className="error">{formik.errors.bathrooms}</div>
                ) : null
              }
            </div>

            <label className="form-label">
              Move-in-date<span className="required">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              {...formik.getFieldProps("availablefrom")}
            />

            <div className="input-group mt-1">
              <span
                className="input-group-text"
                style={{
                  backgroundColor: "white",
                }}
              >
                <AdjustIcon />
              </span>
              <input
                type="text"
                className="form-control"
                defaultValue="As soon as possible"
                disabled
                style={{
                  backgroundColor: "white",
                  borderLeft: "none",
                  marginLeft: "-1rem",
                }}
              />
            </div>

            <div className="mb-3">
              <span className="feature">Features </span>(optional)
              <div className="feature__container">
                <div className="form-check mb-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="furnished"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue("furnished", event.target.checked);
                    }}
                  />
                  <label className="form-check-label" htmlFor="furnished">
                    Furnished
                  </label>
                </div>
                <div className="form-check mb-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="balcony"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue("balcony", event.target.checked);
                    }}
                  />
                  <label className="form-check-label" htmlFor="balcony">
                    Balcony
                  </label>
                </div>
                <div className="form-check mb-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="parking"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue("parking", event.target.checked);
                    }}
                  />
                  <label className="form-check-label" htmlFor="parking">
                    Parking
                  </label>
                </div>
              </div>
            </div>

            {/* Submit button */}
            <button className="btn" type="submit" onClick={handleSubmit}>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateProperty;
