import React from "react";

const AboutProperty = ({ property }: any) => {
  return (
    <div className="mb-3">
      <h3>About Property</h3>
      <hr className="about__hr" />

      <div>
        <div className="d-flex justify-content-between mt-3">
          <span>Property type</span>
          <div>
            <span>{property.category?.name}</span>
          </div>
        </div>
        <hr />
      </div>

      <div>
        <div className="d-flex justify-content-between mt-3">
          <span>Size</span>
          <div>
            <span>
              {property.size} m<sup>2</sup>
            </span>
          </div>
        </div>
        <hr />
      </div>

      <div>
        <div className="d-flex justify-content-between mt-3">
          <span>Rooms</span>
          <div>
            <span
              style={{
                display: property.rooms === 0 ? "none" : "inline-block",
              }}
            >
              {property.rooms}
            </span>
          </div>
        </div>
        <hr />
      </div>

      <div>
        <div className="d-flex justify-content-between mt-3">
          <span>Furnished</span>
          <div>
            <span>{property.furnished ? "Yes" : "No"}</span>
          </div>
        </div>
        <hr />
      </div>

      <div>
        <div className="d-flex justify-content-between mt-3">
          <span>Balcony</span>
          <div>
            <span>{property.balcony ? "Yes" : "No"}</span>
          </div>
        </div>
        <hr />
      </div>

      <div>
        <div className="d-flex justify-content-between mt-3">
          <span>Parking</span>
          <div>
            <span>{property.parking ? "Yes" : "No"}</span>
          </div>
        </div>
        <hr />
      </div>

      <h3>Property Details</h3>
      <hr className="about__hr" />
      <p>{property.description}</p>
    </div>
  );
};

export default AboutProperty;
