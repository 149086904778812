import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import "./singleProperty.scss";
import Carousel from "../../components/image-carousel/Carousel";
import AboutProperty from "./AboutProperty";
import AboutRental from "./AboutRental";
import { getPropertyBySlugService } from "../../service/propertyService";
import ContactLanlord from "./ContactLanlord";

const SingleProperty = () => {
  const { slug } = useParams<{ slug: string }>();

  const [property, setProperty] = useState<any>([]);

  // graphql query to fetch property by slug

  // fetch property by slug
  useEffect(() => {
    const fecthPropertyBySlug = async () => {
      const property = await getPropertyBySlugService(String(slug));
      setProperty(property.property);
    };

    fecthPropertyBySlug();
  }, [slug]);

  return (
    <>
      <Container className="d-flex gap-2 single__property">
        <div>
          <NavLink to="/">Home</NavLink>
        </div>
        <div>{">"}</div>
        <div>
          <NavLink to="/">{property?.category?.name}</NavLink>
        </div>
        <div>{">"}</div>
        <div>
          <NavLink to="/">{property?.city}</NavLink>
        </div>
        <div>{">"}</div>
        <div>
          <NavLink to="/">
            {property.category?.name} of {property.size}m²
          </NavLink>
        </div>
      </Container>
      {property.image && property.image.length > 0 && (
        <Carousel images={property.image} property={property} />
      )}

      <Container className="mt-4">
        <h3>
          <span>
            <h3>Property Description</h3>
            <hr className="about__hr" />
          </span>
          {property.category?.slug} of {property.size}m²
        </h3>
        <span>{property.location}</span>
      </Container>

      <Container>
        <>
          <div className="d-flex justify-content-between mt-4">
            <span>Monthly rent</span>
            <div>
              <span>GHS {property.price}</span>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-3">
            <span>Available from</span>
            <div>
              <span>
                {property.availablefrom
                  ? property.availablefrom
                  : "As soon as possible"}
              </span>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-3">
            <span>Rental period</span>
            <div>
              <span>Unlimited</span>
            </div>
          </div>
          <hr />
        </>
        <AboutProperty property={property} />
        <AboutRental property={property} />
        <ContactLanlord property={property} />
      </Container>
    </>
  );
};

export default SingleProperty;
