import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import RentalProperty from "../pages/rentalproperty/RentalProperty";
import CreateProperty from "../pages/creatproperty/CreateProperty";
import HomePage from "../pages/homepage/HomePage";
import RegistrationVerification from "../pages/verification/RegistrationVerification";
import Activate from "../pages/activate/Activate";
import ForgotPassword from "../pages/forgot-password/ForgotPassword";
import ResetPassword from "../pages/reset-password/ResetPassword";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import AllProperties from "../components/Allproperties/AllProperties";
import SingleProperty from "../pages/SingleProperty/SingleProperty";
import SearchResults from "../pages/search-results/SearchResults";
import Footer from "../pages/footer/Footer";
import PrivacyPolicy from "../pages/privacy-policy/PrivacyPolicy";
import AboutUs from "../pages/about-us/AboutUs";
import CustomerSupport from "../pages/support/Support";
import FAQ from "../pages/faq/Faq";
import CategoryProperty from "../components/categoryProperty/CategoryProperty";
import AppBarMain from "../components/layout/navbar/AppBarMain";
import Profile from "../pages/profile/Profile";

const IndexRouter = () => {
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

  return (
    <BrowserRouter>
      <nav>
        <AppBarMain />
      </nav>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/rental-property" element={<RentalProperty />} />
        {isLoggedIn ? (
          <Route path="/add-property" element={<CreateProperty />} />
        ) : (
          <Route path="/add-property" element={<Navigate to="/" />} />
        )}
        {isLoggedIn ? (
          <Route path="/profile" element={<Profile />} />
        ) : (
          <Route path="/profile" element={<Navigate to="/" />} />
        )}
        <Route path="/properties" element={<AllProperties />} />
        <Route path="/properties/:slug" element={<SingleProperty />} />
        <Route path="/category/:name" element={<CategoryProperty />} />
        <Route path="/search-results" element={<SearchResults />} />
        <Route path="/verify/:id" element={<RegistrationVerification />} />
        <Route path="/activate/:id" element={<Activate />} />
        <Route path="/account/reset-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/support" element={<CustomerSupport />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>

      <footer>
        <Footer />
      </footer>
    </BrowserRouter>
  );
};

export default IndexRouter;
