import { Container } from "@mui/material";
import React from "react";

const AboutUs = () => {
  return (
    <Container>
      <div className="about-us mt-5">
        <h1>About HjemProperties</h1>
        <p>
          Welcome to HjemProperties, your trusted destination for finding your
          dream home. Whether you're searching for a cozy apartment, a spacious
          house, or a stylish condo, we're here to help you discover properties
          that match your lifestyle and preferences.
        </p>

        <h2>Our Mission</h2>
        <p>
          At HjemProperties, our mission is to simplify the process of finding
          and renting properties. We're committed to providing a user-friendly
          platform that empowers individuals and families to make informed
          decisions about their living spaces.
        </p>

        <h2>What We Offer</h2>
        <p>
          With a wide range of property listings, our platform offers a diverse
          selection of homes in various locations. Our user-friendly interface
          and powerful search features make it easy to narrow down your options
          and find properties that match your preferences.
        </p>

        <h2>Why Choose HjemProperties</h2>
        <p>
          Transparency, reliability, and exceptional user experience are the
          cornerstones of HjemProperties. Here's why you should choose us:
        </p>
        <ul>
          <li>
            Comprehensive Listings: Explore a wide range of properties with
            detailed descriptions and images.
          </li>
          <li>
            User-Friendly Interface: Easily navigate our platform to find the
            perfect property.
          </li>
          <li>
            Personalized Search: Customize your search based on location, size,
            price, and more.
          </li>
          <li>
            Trustworthy Information: We verify property details to ensure
            accurate listings.
          </li>
          <li>
            Responsive Support: Our dedicated support team is here to assist you
            every step of the way.
          </li>
        </ul>

        <h2>Contact Us</h2>
        <p>
          Have questions or feedback? We'd love to hear from you! Contact our
          customer support team at hjemproperties@gmail.com or +233 50 892 2801.
        </p>
      </div>
    </Container>
  );
};

export default AboutUs;
