import { Link, useParams } from "react-router-dom";
import { Button, Typography, Container } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import decodeToken from "../../service/decodeToken";
import { resendActivationLinkService } from "../../service/userService/userService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegistrationVerification = () => {
  // get the params from the url
  const { id } = useParams();

  // decode the token from the  id
  const userData: any = decodeToken(String(id));

  // handleResend
  const handleResend = async () => {
    try {
      // resend the verification email
      await resendActivationLinkService(userData);
      toast.success("Verification email has been sent!");
      // refresh the page
      setTimeout(() => {
        window.location.reload();
      }, 7000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container
        className="mb-5"
        maxWidth="sm"
        sx={{ textAlign: "center", mt: 10 }}
      >
        <ToastContainer />
        <EmailIcon sx={{ fontSize: 80, color: "#ED6B2D" }} />
        <Typography variant="h4" sx={{ mt: 4 }}>
          Verify Your Email
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          We've sent an email to your registered address. Please follow the
          instructions in the email to verify your account.
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          Didn't receive the email? Check your spam folder and make sure your
          email is correct. You can also{" "}
          <span
            style={{
              textDecoration: "none",
              color: "#ED6B2D",
              cursor: "pointer",
            }}
            onClick={handleResend}
          >
            resend the verification email
          </span>{" "}
          or{" "}
          <Link to="/" style={{ textDecoration: "none" }}>
            return to the homepage
          </Link>{" "}
          if you have any questions.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ mt: 4 }}
          component={Link}
          to="/"
          style={{
            color: "#fff",
            backgroundColor: "#ED6B2D",
          }}
        >
          Back to Homepage
        </Button>
      </Container>
    </>
  );
};

export default RegistrationVerification;
