import React from "react";
import "./reset.scss";
import { forgotPasswordService } from "../../service/userService/userService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [email, setEmail] = React.useState("");

  // handle change email
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  // handle submit
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await forgotPasswordService(email, toast);

      // reset email
      setEmail("");
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="reset container ">
      <ToastContainer />
      <h4 className="">Reset your password</h4>
      <span>
        Fill out your e-mail address below to receive an e-mail with a link to
        reset your password
      </span>
      <form className="reset__form">
        <input
          type="email"
          className="reset__form__input"
          placeholder="E-mail address"
          value={email}
          onChange={handleEmailChange}
        />
      </form>
      <button onClick={handleSubmit} className="reset__form__button">
        Send e-mail
      </button>
    </div>
  );
};

export default ForgotPassword;
