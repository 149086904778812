import React from "react";

const AboutRental = ({ property }: any) => {
  return (
    <div className="mt-5 mb-4">
      <h3>About Rental</h3>
      <hr className="about__hr" />

      <div>
        <div className="d-flex justify-content-between mt-3">
          <span>Rental period</span>
          <div>
            <span>Unlimited</span>
          </div>
        </div>
        <hr />
      </div>

      <div>
        <div className="d-flex justify-content-between mt-3">
          <span>Available from</span>
          <div>
            <span>
              {property.availablefrom
                ? property.availablefrom
                : "As soon as possible"}
            </span>
          </div>
        </div>
        <hr />
      </div>

      <div>
        <div className="d-flex justify-content-between mt-3">
          <span>Monthly rent</span>
          <div>
            <span>GHS {property.price}</span>
          </div>
        </div>
        <hr />
      </div>

      <div>
        <div className="d-flex justify-content-between mt-3">
          <span>Deposit</span>
          <div>
            <span>GHS {property.deposit}</span>
          </div>
        </div>
        <hr />
      </div>

      <div>
        <div className="d-flex justify-content-between mt-3">
          <span>Creation Date</span>
          <div>
            <span>{new Date(property.createdAt).toLocaleDateString()}</span>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default AboutRental;
