import { Container } from "@mui/material";
import React from "react";

const CustomerSupport = () => {
  return (
    <Container>
      <div className="customer-support mt-3">
        <h1>Customer Support</h1>
        <p>
          At HjemProperties, we are dedicated to providing excellent customer
          support to assist you with any inquiries or concerns you may have. Our
          team is here to ensure your experience with our platform is smooth and
          enjoyable.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions, feedback, or issues, please feel free to
          contact our customer support team. We're available through various
          channels to provide assistance:
        </p>
        <ul>
          <li>
            Email:{" "}
            <a href="mailto:hjemproperties@gmail.com">
              hjemproperties@gmail.com
            </a>
          </li>
          <li>
            Phone: <a href="tel:+233 508922801">+233 508922801</a>
          </li>
        </ul>

        <h2>Frequently Asked Questions</h2>
        <p>
          Before reaching out to our support team, you might find the answer to
          your question in our Frequently Asked Questions (FAQ) section. We've
          compiled a list of common inquiries to help you find solutions
          quickly.
        </p>
        <p>
          Visit our <a href="/faq">FAQ page</a> to learn more.
        </p>

        <h2>Feedback</h2>
        <p>
          We value your feedback and suggestions to improve our platform. If you
          have ideas on how we can enhance your experience or if you encounter
          any issues, please let us know. Your insights are essential to us.
        </p>
        <p>
          Send us your feedback at{" "}
          <a href="mailto:hjemproperties@gmail.com">hjemproperties@gmail.com</a>
          .
        </p>

        {/* Add more content and styling as needed */}
      </div>
    </Container>
  );
};

export default CustomerSupport;
