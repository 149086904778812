import { createSlice } from "@reduxjs/toolkit";

// Initial state for property
const initialState = {
  properties: [],
  loading: false,
  error: null,
};

// Property slice
const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    getAllProperties: (state, action) => {
      state.properties = action.payload;
    },
  },
});

export const { getAllProperties } = propertySlice.actions;

export default propertySlice.reducer;
