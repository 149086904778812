import decode from "jwt-decode";

const decodeToken = (token: string) => {
  try {
    return decode(token);
  } catch (error) {
    return null;
  }
};

export default decodeToken;
