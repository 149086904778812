import axios from "axios";

const baseUrlAPI = process.env.REACT_APP_API;

interface User {
  name: string;
  email: string;
  password: string;
  phone: string;
  address: string;
  city: string;
  postalcode: string;
  country: string;
}

interface LoginUser {
  email: string;
  password: string;
}

// register user service
export const registerUserService = async (userData: User) => {
  try {
    const res = await axios.post(`${baseUrlAPI}/users/register`, userData);

    return res.data;
  } catch (error: any) {
    const err = error.response.data;
    return err.message;
  }
};

// activate user service
export const activateUserService = async (token: string) => {
  try {
    const res = await axios.get(`${baseUrlAPI}/users/verify/${token}`);

    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    return data.message;
  }
};

// login user service
export const loginUserService = async (userData: LoginUser) => {
  try {
    const res = await axios.post(`${baseUrlAPI}/users/login`, userData);
    // store the user data in local storage

    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    return data.message;
  }
};

// resend activation link service
export const resendActivationLinkService = async (userData: User) => {
  try {
    const res = await axios.post(`${baseUrlAPI}/users/resend-email`, userData);
    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    return data.message;
  }
};

// forgot password service
export const forgotPasswordService = async (email: string, toast: any) => {
  try {
    const res = await axios.post(`${baseUrlAPI}/users/forgot-password`, {
      email,
    });
    toast.success(res.data.message);
    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    toast.error(data.message);
    return data.message;
  }
};

// reset password service
export const resetPasswordService = async (
  password: string,
  toast: any,
  token: string
) => {
  try {
    const res = await axios.put(`${baseUrlAPI}/users/reset-password/${token}`, {
      password,
    });
    toast.success(res.data.message);
    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    toast.error(data.message);
    return data.message;
  }
};
