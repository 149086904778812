import Container from "@mui/material/Container";
import "./HomePage.scss";
import HomePageBanner from "../../components/banner/HomepageBanner/HomePageBanner";
import Rentout from "../../components/NewLetters/rent-out/Rentout";
import Categories from "../../components/categories/Categories";
import PopularApartment from "../../components/popular-apartments/PopularApartment";
import RentYourPlace from "../../components/NewLetters/rent-yourplace/RentYourPlace";

const HomePage = () => {
  return (
    <>
      <Container maxWidth={"xl"} className="banner__container">
        <HomePageBanner />
      </Container>
      <Rentout />
      <Container>
        <Categories />
      </Container>
      <Container>
        <RentYourPlace />
      </Container>
    </>
  );
};

export default HomePage;
