import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { registerUserService } from "../../service/userService/userService";
import { Tooltip } from "@mui/material";

// validation schema for login form
const userSchema = Yup.object({
  email: Yup.string().required("Email is required").email("Invalid email"),
  password: Yup.string()
    .min(8, "Minimum 8 characters required")
    .required("Password is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/,
      "Password must contain at least one letter, one number, and one special character"
    ),

  name: Yup.string().required("Name is required"),
  phone: Yup.number().required("Phone is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
});

const Register = ({ handleClose }: any) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isMatchedPassword, setIsMatchedPassword] = useState(false);
  const [ischecked, setIschecked] = useState(false);
  // manage login and signup forms state
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
      phone: "",
      address: "",
      city: "",
    },
    validationSchema: userSchema,
    onSubmit: async (values) => {
      // handle the form submission here

      const user: any = {
        ...values,
      };

      const res = await registerUserService(user);
      if (res.data) {
        setSuccess("Account created successfully, Please Check your email");
        setTimeout(() => {
          setSuccess("");
          handleClose();
        }, 6000);
      } else {
        setError(res);

        // remove error message after 3 seconds
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    },
  });

  return (
    <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3 mt-2">
          <label htmlFor="name" className="form-label">
            Name <span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            required
            {...formik.getFieldProps("name")}
          />
        </div>

        <div className="mb-3 mt-2">
          <label htmlFor="email" className="form-label">
            Email <span className="required">*</span>
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            required
            {...formik.getFieldProps("email")}
          />
        </div>

        <div className="mb-3 mt-2">
          <label htmlFor="password" className="form-label">
            Password <span className="required">*</span>
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            required
            {...formik.getFieldProps("password")}
          />
        </div>

        <div className="mb-3 mt-2">
          <label htmlFor="confirmPassword" className="form-label">
            Confirm Password <span className="required">*</span>
          </label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            required
            onChange={(e) => {
              if (e.target.value === formik.values.password) {
                setIsMatchedPassword(true);
              } else {
                setIsMatchedPassword(false);
              }
            }}
          />
          {!isMatchedPassword && (
            <span className="required">Password does not match</span>
          )}
        </div>

        <div className="mb-3 mt-2">
          <label htmlFor="address" className="form-label">
            Address <span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="address"
            required
            {...formik.getFieldProps("address")}
          />
        </div>

        <div className="mb-3 mt-2">
          <label htmlFor="city" className="form-label">
            city <span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="city"
            required
            {...formik.getFieldProps("city")}
          />
        </div>

        <div className="mb-3 mt-2">
          <Tooltip
            title="Phone number must be in the format 233 244 123 456"
            arrow
            placement="right"
          >
            <label htmlFor="phone" className="form-label">
              phone <span className="required">*</span>
            </label>
          </Tooltip>
          <input
            type="number"
            min={0}
            className="form-control"
            placeholder="233 244 123 456"
            id="phone"
            required
            {...formik.getFieldProps("phone")}
          />
        </div>

        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="alerts"
            required
            onChange={(e) => {
              setIschecked(e.target.checked);
            }}
          />
          <label className="form-check-label" htmlFor="alerts">
            I agree to terms of use
          </label>
        </div>

        <div>
          <span className="required">{error}</span>
          <span className="success">{success}</span>

          <button
            disabled={!ischecked || !isMatchedPassword}
            type="submit"
            className="btn btn-primary"
          >
            Create an account
          </button>
        </div>
      </form>
    </>
  );
};

export default Register;
