import React from "react";
import "./footer.scss";
import { Container } from "@mui/material";

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <div className="d-flex justify-content-between">
          <div className="footer__left">
            <span>HJEMPROPERTIES</span>

            <div>
              <div>
                <a href="/privacy">
                  <span>Privacy Policy</span>
                </a>
              </div>
              <div>
                <a href="/about-us">
                  <span>About us</span>
                </a>
              </div>
            </div>
          </div>

          <div className="footer__right">
            <span>SUPPORT</span>

            <div>
              <div>
                <a href="/support">
                  <span>Customer support</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </Container>
    </div>
  );
};

export default Footer;
