import axios from "axios";
import { gql } from "@apollo/client";

const baseUrlAPI = process.env.REACT_APP_API;

// Get all properties query
export const getAllProperties = gql`
  query {
    properties {
      id
      title
      slug
      description
      price
      image
      location
      status
    }
  }
`;

// Get property by slug query
export const getPropertyBySlug = gql`
  query ($slug: String!) {
    property(slug: $slug) {
      id
      title
      slug
      description
      price
      image
      location
      status
    }
  }
`;

// create property service
export const createPropertyService = async (
  property: any,
  token: string,
  toast: any
) => {
  try {
    const res = await axios.post(`${baseUrlAPI}/properties`, property, {
      headers: {
        Authorization: token,
      },
    });
    toast.success(res.data.message);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    const data = error.response.data;
    toast.error(data.message);
    return data;
  }
};

// get all properties service
export const getAllPropertiesService = async () => {
  try {
    const res = await axios.get(`${baseUrlAPI}/properties`);
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
};

// get property by slug service
export const getPropertyBySlugService = async (slug: string) => {
  try {
    const res = await axios.get(`${baseUrlAPI}/properties/${slug}`);
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
};
