import React from "react";
import "./rentout.scss";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const Rentout = () => {
  const navigate = useNavigate();

  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

  const handleClick = () => {
    try {
      if (!isLoggedIn) {
        toast.error("Please login to continue");
      } else {
        navigate("/add-property");
      }
    } catch (error) {
      return error;
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center  rentout">
        <ToastContainer />
        <div className="right__side">
          <span>Rent out your place and find your next tenant - for free</span>
        </div>
        <div className="left__side">
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            <button className="btn ">Rent out now</button>
          </span>
        </div>
      </div>
    </>
  );
};

export default Rentout;
